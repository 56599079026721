import { ReactElement } from 'react';
import { RootState } from '../../../store';
import { Airline } from '../../../store/airlines/types';
import { getAirlineTemplate } from '../../../store/templates';
import PickerOption from '../../Pickers/PickerOption';
import { selectAirlines } from '../../../store/staticData/selectors';
import { CabModal } from '@CabComponents/CabModal';
import { CabButton } from '@CabComponents/CabButton';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';


interface ComponentProps {
  showModal: boolean;
  selectAirline: (airline: Airline) => void;
  cancelAddAirline: () => void;
}

type Props = ComponentProps;


export const AirlinePickerModal = 
({ showModal, selectAirline, cancelAddAirline }: Props): ReactElement => {

  const airlines = useSelector((state: RootState) => selectAirlines(state.staticData));

  const handleSelectAirline = (id: string | number): void => {
    if (id !== -1) {
      const airline = airlines.filter((curAirline) => curAirline.id === id);
      selectAirline(airline[0]);
    } else {
      selectAirline(getAirlineTemplate());
    }
  };

  const handleCancel = (): void => {
    cancelAddAirline();
  };

  return (
    <CabModal
      open={showModal}
      onClose={handleCancel}
      closeIcon={true}
      closeOnBackdropClick
      title={'Add Airline'}
      actionButtons={
        <CabButton buttonType='secondary' onClick={handleCancel}>
          Cancel
        </CabButton>
      }
      sx={{ 
        '& .MuiDialogActions-root':{
          display: 'flex',
          justifyContent: 'flex-start'
        }
      }}
    >
      <Grid container rowGap={2}>
        {airlines.map((airline): ReactElement => 
          <Grid item xs={4} key={airline.id}>
            <PickerOption src={airline.icon_url} id={airline.id}
              onClick={handleSelectAirline} label={airline.name} isDefault={airline.is_default}/>
          </Grid>
        )}
        <Grid item xs={4}>
          <PickerOption src='' id={-1} onClick={handleSelectAirline} label="Other" 
            isDefault={false}/>
        </Grid>
      </Grid>
    </CabModal>
  );
};

export default AirlinePickerModal;